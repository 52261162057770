.selected-user {
  height: 20px;
  width: 50px;
  display: block;
  border-radius: 5px;
  background-color: white;
}

.select-bvtton--passive {
  display: inline-block;
  position: relative;
  border: none;
  background-color: #08bb7a;
  color: white;
  height: 40px;
  margin: 10px 10px;
  border-radius: 5px;
  padding: 0px 10px;
}

.select-bvtton--active {
  margin: 10px 10px;
  position: relative;
  border: none;
  display: inline-block;
  background-color: #155163;
  color: white;
  height: 40px;
  padding: 0px 20px;
  border-radius: 5px;
}

.select-bvtton--active:disabled {
  display: inline-block;
  position: relative;
  border: none;
  background-color: grey;
  color: white;
  height: 40px;
  margin: 10px 10px;
  border-radius: 5px;
  padding: 0px 20px;
  margin: 10px 10px;
}

.select-bvtton--passive:disabled {
  display: inline-block;
  position: relative;
  border: none;
  background-color: grey;
  color: white;
  height: 40px;
  margin: 10px 10px;
  border-radius: 5px;
  padding: 0px 20px;
  margin: 10px 10px;
}
.upload-csv {
  font-size: 1em;
  border: 1px dashed #155163;
  padding: 10px 20px;
  margin: 10px 10px;
  border-radius: 4px;
  position: relative;
  color: #155163;
}

.upload-csv input[type="file"] {
  display: none;
}

.upload-csv .tooltip {
  background: rgb(180, 180, 180);
  border-radius: 4px;
  padding: 10px;
  font-size: 0.7em;
  color: white;
  font-weight: 500;
  visibility: hidden;
  width: 120px;
  bottom: -30px;
  right: -60px;
  text-align: center;
  padding: 5px 0;
  display: inline-block;
  position: absolute;
  z-index: 1;
}

.upload-csv:hover .tooltip {
  visibility: visible;
}

.modal-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.692);
  z-index: 10000;
}
.model--show{
  display: block;
}

.modal-container .modal {
  height: 500px;
  width: 700px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  box-shadow: 2px 3px 10px rgba(128, 128, 128, 0.445);
}


.modal .modal-action{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.modal-body ul{
  list-style: none;
  height:fit-content;
  max-height: 100px;
  width: 100%;
  /* overflow-y: scroll; */
  display: block;
  /* padding: 10px; */
}

.modal-body li{
  width: 100%;
  height: 2.3em;
}

.modal-body li:hover{
  color :green;
  cursor: pointer;
}



.modal h3{
  margin-top:20px;
}
.modal .modal-body{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  margin-top: 20px;
  border-top: 4px solid #155163;
  flex-direction: column;
  /* padding :20px; */
}
.modal .modal-bottom{
  display:flex;
  position: absolute;
  justify-content: flex-end;
  width: 100%;
  bottom: 0;
  left:0;
}