.bvtton:disabled {
  font-size: .9em;
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.2s ease-in;
  zoom: 1;
  display: inline-block;
  padding: 11px 20px;
  background: grey;
  color: white;
  border: none;
}