.loading,
.module-wrapper.loading {
    position: relative;
}

.spinner,
.module-table .spinner,
.module-content .spinner {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background: rgba(0,0,0,0.27);
}
._row_div {
    display: flex;
    flex-flow: row;
}
._wallet_option {
    border: 1px solid rgba(0, 0, 0, 0.1);
    outline: none;
    margin: -5px 0 0 5px;
}
.duplicate_field {
    margin-top: -3%;
    height: 60px;
    width: 100%;
    background-color: white;
}

.dup_header {
    color: #127CCB;
    font-size: 20px;
    margin-left: 30px;
}

.num__ {
    width: 40px;
    height: 28px;
}

.dup_div {
    display: flex;
    flex-direction: row;
    padding-top: 15px;
}

.details_ {
    margin-right: 8px;
    font-size: 18px;
    margin-left: 20px;
    padding-top: 2px;
}

.btn__ {
    height: 29px;
    width: 225px;
    font-size: 10px;
    margin-left: 15px;
    color: white;
    margin-bottom: 50px;
}

.tab__ {
    margin: 20px 0 20px 0;
}

.form_div {
    margin-left: 30%;
    width: 100%;
}

.dup_form {
    display: flex;
    flex-direction: column;
}

.selectDriver {
    display: none;
}

.dropdown__{
    display: flex;
    flex-direction: row;
    margin-right: 5px;
}

.dp_btn {
    height: 30px;
    width: 160px;
    margin: 10px 0 0 20px;
}

.route-days {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.route_names {
    width: 20px;
}

.route_label {
    margin-left: 35px;
    width: 50px;
    height: 30px;
}

.sn_ {
    width: 120px;
    font-size: 13px;
}

.label_rd {
    font-size: 20px;
    font-weight: bold;
}

.update_seats_btn {
    height: 30px;
    width: 80px;
    font-size: 12px;
    margin-left: 20px;
}

.check_label {
    margin-top: 5px;
}

.checbbox_sec {
    display: flex;
    flex-direction: column;
}

.main_label {
    font-size: .9em;
    font-weight: 500;
    padding: .2em .6em .3em;
}

.check_box {
    margin-right: 5px;
}

.flex_label {
    display: flex;
    flex-direction: row;
}

.p {
    margin: 0 0 0 5px;
    font-size: .85em;
}

.route_btn {
    margin-top: 10px;
}

.corporates_div {
    margin: 10px 0px;
}

.select_corporate {
    margin-left: 7px;
    width: 150px;
    outline: none;
}

.file_sec {
    background-color: white;
    height: 200px;
}

.upload_btn {
    width: 200px;
}

.file_label {
    font-weight: bold;
}

.edit_file {
    height: 40px;
    width: 200px;
    background-color: #057BFF;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
}

#luggage-config tr:nth-child(even){background-color: #f2f2f2;}


.file_name {
    color: red;
}

.no_show {
    display: none;
}

.submit_file {
    outline: none;
    width: 100px;
    border: none;
}

.upload_h4 {
    margin-bottom: 20px;
}

.upload_details {
    font-weight: 500;
    margin-bottom: 10px;
}

.upload_list {
    margin-left: 15px;
}

.upload_list_success {
    color: #3BC691;
}

.upload_list_error {
    color: #EC8492;
}

.future_route {
    display: flex;
    flex-direction: row;
}

.future_route_label {
    font-weight: 500;
    margin: 0 20px 20px 8px;
}
.burger{
    margin: auto;
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    display: none;
}
.burger_line, .child_1, .child_2, .child_3 {
    width: 30px;
    height: 4px;
    background: white;
    margin-bottom: 1px;
    transform: rotate(0);
    transition: all 0.3s linear;
}

.child_1 {
    transform: rotate(45deg);
    background-color: rgb(121, 119, 119);
}

.child_2 {
    transform: translateX(100%);
    opacity: 0;
    background-color: rgb(121, 119, 119);
}

.child_3 {
    transform: rotate(-45deg);
    background-color: rgb(121, 119, 119);
}

@media (max-width: 768px) {
    .burger {
        margin-left: 40%;
        display: block;
        width: 0%;
        margin-right: -10%;
    }

    header .navigation ul {
        display: none;
    }

    .admin-sidebar {
        background-color: #212B35;
        position: fixed;
        top: 2%;
        width: 50%;
        right: 0;
    }
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
    .burger {
        margin-left: 30%;
    } 
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
    .burger {
        margin-left: 30%;
    } 
}


@media only screen and (min-width: 320px) and (max-width: 425px) {
    .admin-sidebar {
        width: 70%;
    }
}

.burgerNav {
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
}

.test {
    height: 50px;
    width: 50px;
    background-color: red;
    /* position: fixed;
    top: 0;
    right: 100%; */
}

._top_ul {
    margin-left: 15px;
}

.generate_ {
    text-align: center;
    line-height: 0;
}

.filters__ , .__btn__{
    padding-left: 15px;
    font-weight: 500;
    line-height: 2.5;
}

.filter_btn, .__filter_btn {
    margin: 0 20px;
    height: 35px;
    width: 100px;
    color: white;
    background: #09BB7A;
    border-radius: 5px;
    border: none;
    outline:none;
}

.userRoutesLayout{
    background: #FFF;
    margin-left: 2%;
    height: 300px;
}

.no_user_route {
    height: 100px;
    width: 100%;
    background: #FFF;
    margin-top: 5%;
}

.staff_role {
    display: flex;
    flex-flow: row;
}

.select-row {
    width: 300px;
    margin-left: 20px;
}

.notFound {
    height: 500px;
    display: flex;
    flex-flow: row;
}

.messageDiv, .messageDivRight {
    width: 50%;
    height: 120%;
}

.banner_img_{
    width: 100%;
    height: 100%;
}

.message {
    margin-top: 40%;
    margin-left: 15%;
}

.__text {
    color: rgb(243, 101, 101)
}

.side_filters {
    display: flex;
    flex-flow: row;
    width: 50%;
    margin-top: 2%;
}


.__filters__ {
    font-size: 20px;
    font-weight: bold;
}

.__filter_btn {
    background: #155163;
}
.form-check-label {
    font-weight: 400;
}

.date-container {
    margin-bottom: 3%;
}

.daysSection {
    display: flex;
    flex-flow: row;
    margin-bottom: 3%;
}

.trip-weeks {
    margin-top: 3%;
}


.luggageLabel {
    font-weight: 400;
    margin: 0 10px;
}

.tripModule {
    margin-left: 1.5%;
}

.trip_label {
    margin-left: 3%;
}
.endTripsSec {
    height: 20px;
    width: 100%;
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;
}
._rowDiv_ {
    display: flex;
    flex-flow: row;
}
.endActionBtn {
    height: 20px;
    margin: auto;
    font-size: 10px;
    color: white;
    background: rgb(241, 105, 105);
    outline: none;
    border-radius: 2px;
    border: none;
}
.label_end_trip {
    display: flex;
    flex-flow: row;
}

.routeList_ {
    display: flex;
    flex-flow: row;
    width: 100%;
    margin-left: 3%;
    margin-top: 5%;
    margin-bottom: 5%;
}
.file-select > .select-button {
    padding: 1rem;
    color: white;
    border-radius: .3rem;
    text-align: center;
    font-weight: bold;
    cursor: pointer
}
.file-select > input[type="file"] {
    display: none;
}
.upload {
    width:30%;
    margin-top: 0
}
.fileBtn {
    width: 100px;
    height: 40px;
    color: white;
    border: 1px solid;
    text-align: center;
    margin: auto;
    font-size: 13px;
    border-radius: 5px;
    padding-top: 10px;
    background: #155163;
}
.fileSection{
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-flow: row;
}
.imgDivUpload {
    padding-top: 6%;
}
.main_btn_notify {
    width: 300px;
}
.formSec{
    padding-bottom: 3%;
}
.fileNameSec {
    width: 100%;
    padding-left: 4%;
}
.flashCheck{
    width: 35%;
}
.routeSearch {
    display: flex;
    flex-flow: column;
}
.autoCompleteInputBar {
    width: 100%;
    border: 1px solid #E5E5E5;
    height: 45px;
    outline: none;
}
.autoCompleteInputBar:hover {
    border: 1px solid rgba(8, 187, 122, 0.4);;
}
.autoCompleteInputBar::placeholder {
    padding-left: 6px;
}
.paymentSource {
    display: flex;
    flex-flow: row;
}
.selectSource {
    margin-top: -5px;
}
.section_ {
    width: 50%
}
.map_sec{
    width: 500px;
    height:  100%;
}