.dashboard-loading {
    position: relative;
}
.dashboard-loading:after {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    background: rgba(0,0,0,0.65);
}

.btns {
    height: 30px;
    margin-left: 10px;
    font-size: 12.5px;
    border-radius: 3px;
}

._download {
    margin-top: 15px;
    width: 100px;
    height: 40px;
}
.dashboard-loading .spinner:before{
    z-index: 10;
}

.admin-ui-nav a.active{
    color: #127CCB;
}
.admin-ui-nav .active + ul {
    display: block;
}

.map-image > img {
    max-width: 100%;
}

.mt-1, mx-1, m-1 {
    margin-top: 0.5rem;
}
.mt-2, my-2, m2 {
    margin-top: 1rem;
}
.mt-3, mx-3, m3 {
    margin-top: 1.5rem;
}

.mr-1, mx-1, m-1 {
    margin-right: 0.5rem;
}
.mr-2, my-2, m2 {
    margin-right: 1rem;
}
.mr-3, mx-3, m3 {
    margin-right: 1.5rem;
}

.mb-1, mx-1, m-1 {
    margin-bottom: 0.5rem;
}
.mb-2, my-2, m2 {
    margin-bottom: 1rem;
}
.mb-3, mx-3, m3 {
    margin-bottom: 1.5rem;
}

.ml-1, mx-1, m-1 {
    margin-left: 0.5rem;
}
.ml-2, my-2, m2 {
    margin-left: 1rem;
}
.ml-3, mx-3, m3 {
    margin-left: 1.5rem;
}

.btn_stack {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.check_btn {
    width: 100px;
    font-size: 11px;
    text-align: center;
}

.bg_white {
    width: 90%;
    background: white;
    height: 100px;
}

.bg_text {
    color: red;
    padding-top: 20px;
    font-size: 25px;
    text-align: center;
}

.userCount {
    float: right;
    margin-right: 100px;
    margin-top: 20px;
}
.container__ {
    width: 100%;
    display: flex;
    flex-flow: column;
}
.bold_text {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1.5px;
}
.formBody_ {
    justify-content: unset !important;
}
.container__ {
    background: white;
    padding: 10px;
}
.main__ {
    display: flex;
    flex-flow: column;
}
.dateSec_ {
    margin-right: 5px;
}
.checkbox_section__ {
    display: flex;
    flex-flow: row;
    margin-top: 2%;
}
.radioLabel {
    font-size: 14px;
    margin-right: 15px;
    color: #777777;
    font-weight: 500;
}
.statusRadio {
    margin-right: 20px;
}
.eachStatusRadio {
    margin-right: 10px;
}
.mainBtn__ {
    width: 200px;
    margin-top: 5%;
}