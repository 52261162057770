.virtualize-box {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 3px 12px;
    display: flex;
    align-items: center;
    max-width: 400px;
    position: relative;
}

.virtualize-input {
    border: none !important;
    padding: 10px;
    width: 80%;
    font-size: 13px;
    outline-color: transparent;
    border-color: transparent !important;
}

.virtualize-search-icon {
    font-size: 21px;
}

.virtualize-suggest-box {
    position: absolute;
    top: 50px;
    left: 0;
    background: white;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    z-index: 10;
    box-shadow: 1px 0px 3px #ccc;
    max-height: 300px;
    overflow: scroll;
}

.card {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    /* border: 1px solid rgba(0,0,0,.1); */
    background-color: #fff;
    min-height: 200px;
    border-radius: 5px;
    padding: 15px;
}

.round-image {
    background: #f2f2f2;
    border-radius: 50%;
    overflow: hidden;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.align-center {
    align-items: center;
    display: flex;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.caption {
    font-size: 12px !important;
    margin-top: 20px !important;
}

.caption-secondary {
    margin: 0px !important;
    opacity: 0.5;
    font-style: italic;
}