.text-segment {
    padding: 5px 0px;
    border-bottom: 1px solid #f2f2f2;
    width: 55%;
}

.card-text {
    margin: 0px;
    color: #333;
    font-weight: 400;
    font-size: 14px;
}

.card-caption {
    font-size: 10px;
    color: #949292;
}

.suggest-list-item {
    padding: 5px;
    cursor: pointer;
}

.suggest-list-item:hover {
    background: #f2f2f2;
}

.suggest-list-item p {
    margin-bottom: -5px;
}

.suggest-list-item span {
    font-size: 11px;
    color: #9a9898;
}

.status-modal-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.status-modal-content p {
    font-size: 30px;
    font-weight: 300;
    margin: auto
  }

.status-modal-content img {
  margin: auto
}


.status-modal-button{
  width: 100px;
    margin: 0px auto;
    background: #212B34;
    border: 1px solid #212B34;
    color: white;
    border-radius: 3px;
    padding: 6px;
}

.status-modal {
  top: 20%;
  left: 30%;
  right: auto;
  bottom: auto;
  height: 40vh;
  border-radius: 5px;
  width: 40%;
  min-width: 350px;
  padding: 20px;
  justify-content: space-around;
  margin-right: -50%;
  display: flex;
  flex-direction: column;
  position: relative;
  transform: translate(-50%, -50%);
};

.status-modal-overlay {
    background-color: #212b34cf;
    z-index: 10;
}

.loc_desc {
    margin-bottom: 1%;
    width: 25%;
    height: 100px;
    border: 1px solid hsl(0, 0%, 80%);
}

.update_wrapper {
    margin: 10px 0 0 20px;
} 

.update_bus_stop {
    display: flex;
    flex-direction: column;
}

.update_btn {
    width: 80px;
    margin: 1% 0 0 8%;
}

.label_ {
    font-size: 20px;
    outline: none;
    text-decoration: none;
    margin-bottom: 2%;
}

.loc_text_ {
    width: 25%;
    margin-bottom: 15px;
    text-decoration: none;
    outline: none;
}

.busstopForm {
    width: 40%;
    margin-bottom: 2%;
}

._main_wrap {
    height: 600px;
    width: 90%;
    margin: 5% 5%;
}

.row_ {
    display: flex;
    flex-flow: row;
    width: 100%;
}